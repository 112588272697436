import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexSubmariner = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexSubmariner {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Submariner/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Submariner Watches for Sale'}
      canonical={'/fine-watches/rolex/rolex-submariner-watch/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Submariner Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/rolex/rolex-submariner/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-submariner-header.png"
                alt="Pre-Owned Certified Used Rolex Submariner Watches Header"
                aria-label="Used Rolex Submariner Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX SUBMARINER: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX WATCHES</h2>
            <p>
              <b>Pre-Owned Rolex Submariner</b>
              <br />
              If you want to know where to sell your used and pre owned Rolex watches for cash, come
              to Gray and Sons Jewelers in Miami, FL. Online at <a href="/">www.grayandsons.com </a>
              will buy your watch for cash. Gray and Sons is the #1 place to repair Rolex Submariner
              watches, buy used Rolex Submariner watches, and sell Rolex Submariner watches. At Gray
              and Sons, we buy, sell, trade and repair used and pre owned Rolex Submariner watches.
              <br />
              <br />
              With over six decades of history behind it, the Rolex Submariner is the world’s most
              famous and beloved luxury dive watch. Whether vintage, discontinued, or current,
              pre-owned Rolex Submariner watches are one of the best-selling timepieces in the
              secondary market. Therefore, they can be difficult to find if you don’t know where to
              look.
              <br />
              <br />
              <b>Buying and Selling Used Rolex Submariner Watches</b>
              <br />
              Lucky for you, Gray &amp; Sons has a wide range of pre-owned Submariner watches in
              stock right now. As an independent Rolex Submariner dealer, we not only have inventory
              control but also have price flexibility. When you’re ready to buy a used Submariner
              watch, reach out to one of our <a href="/chat-with-rich">decision-makers </a>
              immediately to make an offer. On the other hand, if you’ve recently asked yourself,
              “Where should I sell my used Rolex Submariner,” visit
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com </a>
              for the best cash offer.
              <br />
              <br />
              One thing to consider when buying or selling used Rolex Submariner watches is that
              there are two main variants: ones without date windows and one with date windows.
              No-Date Submariners are always in stainless steel and have black dials and bezels.
              Conversely, Submariner Date models are available in steel, yellow gold, two-tone steel
              and yellow gold, and depending on the model, can have black (LN), blue (LB), or green
              (LV) bezels and dials.
              <br />
              <br />
              Pre-Owned Submariner (no-date) References we buy and sell: 6204, 6205, 6200, 6538,
              6536, 5508, 5510, 5512, 5513, 14060, 114060, 124060
              <br />
              <br />
              Pre-owned Submariner Date References we buy and sell: 1680, 16800, 16808, 16803,
              168000, 16610LN, 16610LV, 16618, 16613, 116618LN, 116618LB, 116619LB, 116613LN,
              116613LB, 116610LN, 116610LV, 126618LN, 126618LB, 126619LB, 126613LN, 126613LB
              126610LN, 126610LV
              <br />
              <br />
              <b>
                The Best Source for Buying and Selling Secondhand Rolex Submariner is Gray &amp;
                Sons
              </b>
              In contrast to the virtual-only e-commerce watch stores on the internet that don’t
              necessarily carry an inventory, Gray and Sons is a real store (open six days a week)
              with plenty of luxury watches, including secondhand Rolex Submariners. We are located
              in the Surfside/Miami area, across from the prestigious Bal Harbour Shops, and our
              watch and jewelry experts are always available in-person to help you sell or buy
              pre-owned Rolex Submariner watches.
              <br />
              <br />
              With over 40 years of experience buying and selling used Rolex luxury watches, our
              customers say that Gray and Sons are the #1 Rolex Submariner watch buyer and seller.
              We also have a Rolex Submariner maintenance, service, and repair facility on-site. We
              offer certified authentic and certfied pre-owned watches.
              <br />
              <br />
              Locally, we serve the Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach,
              Bal Harbour, Surfside, Sunny Isles, Ventura, Fort Lauderdale, Pinecrest, and Brickle
              areas. However, our large watch inventory is also accessible to anyone in the country
              thanks to our safe and secure online store.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/rolex/rolex-submariner-watch/">
                <button>SHOP ROLEX SUBMARINER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>
      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX SUBMARINER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexSubmariner
